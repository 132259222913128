import { Component ,NgZone,OnInit} from '@angular/core';
import { AppLogo, AppName } from './services/constants/constant';
import { ApiService } from './services/api/api.service';
import { async } from 'rxjs';
import { NavigationExtras, Router } from '@angular/router';
// import OneSignal from 'onesignal-cordova-plugin';
// import { OneSignal } from 'onesignal-ngx';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';
import { NavController, Platform } from '@ionic/angular';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
declare var OneSignal: any;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent{
  appLogo = AppLogo;
  appName = AppName;
  userType:any;
  public appPagesAdmin = [
    { title: 'Lead Master', url: '/lead-master', icon: 'briefcase',isOpen: 0 },
    { title: 'Information Master', url: '/folder/Archived', icon: 'archive', isOpen: 0,
      subList: [
        // {
        //   title: 'Company Master',
        //   url: '/company-master',
        //   icon: 'cog',
        // },
        {
          title: 'Client Master',
          url: 'client-master',
          icon: 'person-circle',
        },
        {
          title: 'Employee Master',
          url: '/user-master',
          icon: 'person',
        },
        // {
        //   title: 'Role/Permission  Master',
        //   url: '/role-master',
        //   icon: 'cog',
        // },
        // {
        //   title: 'Status Master',
        //   url: '/status-master',
        //   icon: 'stats-chart',
        // },
        {
          title: 'Device Master',
          url: '/device-master',
          icon: 'desktop',
        },
        {
          title: 'Product Master',
          url: '/software-master',
          icon: 'invert-mode',
        },
        {
          title: 'Technology Master',
          url: '/technology-master',
          icon: 'cog',
        },
        {
          title: 'Version Master',
          url: '/version-master',
          icon: 'server',
        },
        {
          title: 'AddOn Master',
          url: '/add-on-master',
          icon: 'add',
        }
      ],
    },
    // { title: 'Home Page Master', url: '/page-customizer', icon: 'briefcase' },
  ];

  public appPagesEmployee = [
    { title: 'Lead Master', url: '/lead-master', icon: 'briefcase',isOpen: 0 },
  ];
  constructor(
    private apiService: ApiService,
    private router: Router,
    private zone: NgZone,
    private navCtrl:NavController,
    private platform:Platform,
    // private oneSignal: OneSignal
  ) {
    this.userType = localStorage.getItem('userType');
    // console.log('this.userType',this.userType);

    this.apiService.userLoggedIn.subscribe(()=>{
      this.userType = localStorage.getItem('userType');
      console.log('this.userType',this.userType);
    })
    // this.initializeApp();
    this.initOneSignal();
    // console.log(this.oneSignal);
    // this.oneSignal
    // .init({
    //   appId: environment.oneSignalAppId,
    //   serviceWorkerParam: {
    //     scope: "/assets/"
    //   },
    //   serviceWorkerPath: '/assets/OneSignalSDKWorker.js'
    // }).then((oneSignalData) => {
    //   // do other stuff
    //   console.log("oneSignal initializeApp",oneSignalData)
    // });
  }

  ngOnInit() {
  }
  

  // initOneSignal() {
  //   console.log("onesignal init")
  //   if (Capacitor.isNativePlatform()) {
  //     //Remove this method to stop OneSignal Debugging
  //     window['plugins'].OneSignal.setLogLevel({ logLevel: 6, visualLevel: 0 });
  //     var that = this;
  //     var notificationOpenedCallback = function (jsonData) {
  //       console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
  //       var redirectId = jsonData.notification.payload.additionalData.id;
  //       var order_id = jsonData.notification.payload.additionalData.order_id
  //         ? jsonData.notification.payload.additionalData.order_id
  //         : null;
  //       that.redirectWithId(redirectId, order_id);
  //     };

  //     // Set your iOS Settings
  //     var iosSettings = {};
  //     iosSettings['kOSSettingsKeyAutoPrompt'] = true;
  //     iosSettings['kOSSettingsKeyInAppLaunchURL'] = false;

  //     window['plugins'].OneSignal.startInit(environment.oneSignalAppId)
  //       .handleNotificationOpened(notificationOpenedCallback)
  //       .iOSSettings(iosSettings)
  //       .inFocusDisplaying(
  //         window['plugins'].OneSignal.OSInFocusDisplayOption.Notification
  //       )
  //       .endInit();

  //     window['plugins'].OneSignal.promptForPushNotificationsWithUserResponse(
  //       function (accepted) {
  //         console.log('User accepted notifications: ' + accepted);
  //       }
  //     );
  //   }
  // }


  initOneSignal() {
    OneSignal.push(() => {
      // OneSignal.init({
      //   appId: environment.oneSignalAppId,
      // });

      OneSignal.getUserId().then((userId) => {
        console.log('OneSignal User ID:', userId);
      });
      OneSignal.showHttpPrompt();

      OneSignal.on('notificationDisplay', (event) => {
        console.log('OneSignal notification displayed:', event);
      });
    
      OneSignal.on('notificationOpened', (event) => {
        console.log('OneSignal notification opened:', event);
      });
    });

  }

  // initOneSignal() {
  //   this.platform.ready().then(() => {
  //     this.oneSignal.startInit(environment.oneSignalAppId, 'YOUR_FIREBASE_SENDER_ID');
  //     this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);
  
  //     this.oneSignal.handleNotificationReceived().subscribe(data => {
  //       // Handle received notification
  //     });
  
  //     this.oneSignal.handleNotificationOpened().subscribe(data => {
  //       // Handle opened notification
  //     });
  
  //     this.oneSignal.endInit();
  //   });
  // }

  redirectWithId(redirectId, order_id?) {
    this.zone.run(() => {
      console.log(order_id);

      if (order_id) {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            id: order_id,
          },
        };
        this.navCtrl.navigateForward([redirectId], navigationExtras);
      } else {
        console.log('TESTING IN REDIRECT WITHOUT ID')
        this.router.navigate([redirectId]);
      }
    });
  }

}
