import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'folder/:id',
    loadChildren: () =>
      import('./folder/folder.module').then((m) => m.FolderPageModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  // {
  //   path: 'company-master',
  //   loadChildren: () =>
  //     import('./company-module/company-master/company-master.module').then(
  //       (m) => m.CompanyMasterPageModule
  //     ),
  // },
  // {
  //   path: 'company-create',
  //   loadChildren: () =>
  //     import('./company-module/company-create/company-create.module').then(
  //       (m) => m.CompanyCreatePageModule
  //     ),
  // },
  {
    path: 'my-profile',
    loadChildren: () =>
      import('./my-profile/my-profile.module').then(
        (m) => m.MyProfilePageModule
      ),
  },
  {
    path: 'user-master',
    loadChildren: () =>
      import('./user-module/user-master/user-master.module').then(
        (m) => m.UserMasterPageModule
      ),
  },
  {
    path: 'user-create',
    loadChildren: () =>
      import('./user-module/user-create/user-create.module').then(
        (m) => m.UserCreatePageModule
      ),
  },
  {
    path: 'system-setting',
    loadChildren: () =>
      import('./system-setting/system-setting.module').then(
        (m) => m.SystemSettingPageModule
      ),
  },
  {
    path: 'status-master',
    loadChildren: () =>
      import('./status-module/status-master/status-master.module').then(
        (m) => m.StatusMasterPageModule
      ),
  },
  {
    path: 'status-create',
    loadChildren: () =>
      import('./status-module/status-create/status-create.module').then(
        (m) => m.StatusCreatePageModule
      ),
  },
  {
    path: 'device-create',
    loadChildren: () => import('./device-module/device-create/device-create.module').then((m) => m.DeviceCreatePageModule),
  },
  {
    path: 'device-master',
    loadChildren: () => import('./device-module/device-master/device-master.module').then( m => m.DeviceMasterPageModule)
  },
  {
    path: 'software-master',
    loadChildren: () => import('./software-module/software-master/software-master.module').then( m => m.SoftwareMasterPageModule)
  },
  {
    path: 'software-create',
    loadChildren: () => import('./software-module/software-create/software-create.module').then( m => m.SoftwareCreatePageModule)
  },
  {
    path: 'technology-master',
    loadChildren: () => import('./technology-module/technology-master/technology-master.module').then( m => m.TechnologyMasterPageModule)
  },
  {
    path: 'technology-create',
    loadChildren: () => import('./technology-module/technology-create/technology-create.module').then( m => m.TechnologyCreatePageModule)
  },
  {
    path: 'client-master',
    loadChildren: () => import('./client-module/client-master/client-master.module').then( m => m.ClientMasterPageModule)
  },
  {
    path: 'client-create',
    loadChildren: () => import('./client-module/client-create/client-create.module').then( m => m.ClientCreatePageModule)
  },
  {
    path: 'role-master',
    loadChildren: () => import('./role-permission-module/role-master/role-master.module').then( m => m.RoleMasterPageModule)
  },
  {
    path: 'role-create',
    loadChildren: () => import('./role-permission-module/role-create/role-create.module').then( m => m.RoleCreatePageModule)
  },
  {
    path: 'permission-master',
    loadChildren: () => import('./role-permission-module/permission-master/permission-master.module').then( m => m.PermissionMasterPageModule)
  },
  {
    path: 'lead-master',
    loadChildren: () => import('./lead-module/lead-master/lead-master.module').then( m => m.LeadMasterPageModule)
  },
  {
    path: 'lead-create',
    loadChildren: () => import('./lead-module/lead-create/lead-create.module').then( m => m.LeadCreatePageModule)
  },
  {
    path: 'version-create',
    loadChildren: () => import('./version/version-create/version-create.module').then( m => m.VersionCreatePageModule)
  },
  {
    path: 'version-master',
    loadChildren: () => import('./version/version-master/version-master.module').then( m => m.VersionMasterPageModule)
  },
  {
    path: 'add-on-create',
    loadChildren: () => import('./addOn-module/add-on-create/add-on-create.module').then( m => m.AddOnCreatePageModule)
  },
  {
    path: 'add-on-master',
    loadChildren: () => import('./addOn-module/add-on-master/add-on-master.module').then( m => m.AddOnMasterPageModule)
  },
  {
    path: 'company',
    loadChildren: () => import('./company/company.module').then( m => m.CompanyPageModule)
  },
  {
    path: 'lead-details',
    loadChildren: () => import('./lead-module/lead-details/lead-details.module').then( m => m.LeadDetailsPageModule)
  },
  {
    path: 'lead-details',
    loadChildren: () => import('./lead-module/lead-details/lead-details.module').then( m => m.LeadDetailsPageModule)
  },
  {
    path: 'update-status',
    loadChildren: () => import('./lead-module/update-status/update-status.module').then( m => m.UpdateStatusPageModule)
  },
  {
    path: 'delete-module',
    loadChildren: () => import('./delete-module/delete-module.module').then( m => m.DeleteModulePageModule)
  },
  {
    path: 'reminder-pop-up-module',
    loadChildren: () => import('./lead-module/reminder-pop-up-module/reminder-pop-up-module.module').then( m => m.ReminderPopUpModulePageModule)
  },  {
    path: 'lead-status',
    loadChildren: () => import('./lead-module/lead-status/lead-status.module').then( m => m.LeadStatusPageModule)
  },
  {
    path: 'employee-report',
    loadChildren: () => import('./employee-report/employee-report.module').then( m => m.EmployeeReportPageModule)
  },
  {
    path: 'employee-attandance-report',
    loadChildren: () => import('./employee-attandance-report/employee-attandance-report.module').then( m => m.EmployeeAttandanceReportPageModule)
  },




  // {
  //   path: 'page-customizer',
  //   loadChildren: () => import('./home-page-tool/page-customizer/page-customizer.module').then( m => m.PageCustomizerPageModule)
  // },
  // {
  //   path: 'element-create',
  //   loadChildren: () => import('./home-page-tool/element-create/element-create.module').then( m => m.ElementCreatePageModule)
  // },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
