export const AppLogo = 'https://approch.co.in/wp-content/uploads/2020/09/logo.png';
export const AppName = 'Approch';

export const ApiBaseUrl = 'https://crm.nybizz.es/api/';
// export const ApiBaseUrl = 'https://admin.approchindia.in/api/';


export const Data_Urls={
  login:'auth/login',
  registor:'auth/register',
  forgot_password:'auth/forgot-password',
  logout:"auth/logout",
  otp_verify:'auth/otp-verification',
  reset_password:'auth/reset-password',
  verify_email:'auth/verify-email',
  resend_verification_link:'auth/resend-verify-email',
  view_profile :  'auth/view-profile', 
  edit_profile : 'auth/edit-profile',
  change_password : 'auth/change-password',
}
