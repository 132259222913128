import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from '../constants/constant';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
 
  users = ''
  userProfileDetail:any;
  @Output() userLoggedIn = new EventEmitter<any>();
  @Output() updateLead = new EventEmitter<any>();
  constructor(private http: HttpClient,
    private alertController : AlertController) { }
  //  headers = new HttpHeaders().set(
  //   'Authorization','Bearer' + ' ' + localStorage.getItem('access_token'));

  public getApi(apiRoute): Observable<any> {
   let header = new HttpHeaders().set(
      'Authorization','Bearer' + ' ' + localStorage.getItem('access_token'));
    return this.http.get(ApiBaseUrl + apiRoute, {headers:header});
  }

  public postApi(apiRoute, data) : Observable<any>{
    let header = new HttpHeaders().set(
      'Authorization','Bearer' + ' ' + localStorage.getItem('access_token'));
    return this.http.post(ApiBaseUrl + apiRoute, data, { headers: header });
  }

  public putApi(apiRoute, data) : Observable<any>{
    let header = new HttpHeaders().set(
      'Authorization','Bearer' + ' ' + localStorage.getItem('access_token'));
    return this.http.put(ApiBaseUrl + apiRoute, data, { headers: header });
  }

  public deleteApi(apiRoute) : Observable<any>{
    return this.http.delete(ApiBaseUrl + apiRoute);
  }

  async openToast(msg:any) {  
    const alert = await this.alertController.create({  
      message: msg,  
      buttons: ['ok'],
    });  
    await alert.present();
  }

  login(apiRoute, data) {
       return new Promise((resolve,reject) => {
       this.http.post(apiRoute,data).subscribe((user:any) => {
            // console.log(`user==>`,user);
          //   let isUserValid = user.users.find(e => { return (e.email == data.email && e.password == data.password)});
          // if(isUserValid) {
          //   resolve({'status': '200' ,'data': isUserValid});
          // } 
          // else {
          //   reject({'status': '400' ,'error': 'invalid credentials'})
          // }
        });
      })
  }

async  userDetails() {
    this.getApi('auth/view-profile').subscribe(res => {
    // console.log(res); 
    localStorage.setItem('userType', res?.type);
  }, err => {
    console.log(err);
  })
  }
}
